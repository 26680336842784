(function () {
    'use strict';

    var appModule = angular.module('app');

    appModule.controller('DocumentsPreviewCtrl', [
        '$scope', '$modalInstance', 'fileId', 'fileTitle', 'storagePath', 'brandingColour', '$sce', 'constants', 'documentsService', '$q', 'ENVIRONMENTAL', '$window',
        function ($scope, $modalInstance, fileId, fileTitle, storagePath, brandingColour, $sce, constants, documentsService, $q, ENVIRONMENTAL, $window) {

            $scope.title = fileTitle;
            $scope.close = function() {
                $modalInstance.dismiss('cancel');
            }

            $scope.error = false;
            $scope.errorMessage = "Error loading document please try again.";

            function onMessageHandler(e) {
                if (e.originalEvent.data === "error") {
                    $scope.$apply(function() {
                        $scope.error = true;
                    });
                }
            };

            $($window).on("message", onMessageHandler);

            $scope.$on('$destroy', function () {
                $($window).off("message", onMessageHandler);
            });

            if (brandingColour == null || brandingColour.length !== 6) {
                brandingColour = "00ACD4";
            }
            if (storagePath === null) {
                documentsService.getDownloadForPreview(fileId).then(function (data) {
                    if (data === null || data === undefined || data.fileUrl === null || data.fileUrl === '') {
                        $scope.errorMessage = "Error while finding the requested document for previewing. Please contact support.";
                        $scope.error = true;
                        $scope.$apply();
                    } else {
                        $scope.url = $sce.trustAsResourceUrl(ENVIRONMENTAL.BASEURLS.PREVIEW + constants.DOCUMENTS.URLS.PREVIEWURL + encodeURIComponent(data.fileUrl) + constants.DOCUMENTS.URLS.PREVIEWTITLE + fileTitle + constants.DOCUMENTS.URLS.PREVIEWCOLOR + brandingColour);
                    }
                });
            } else {
                documentsService.getAnnouncementDownload(storagePath).then(function (data) {
                    if (data === null || data === undefined || data.fileUrl === null || data.fileUrl === '') {
                        $scope.error = true;
                        $scope.$apply();
                    } else {
                        $scope.url = $sce.trustAsResourceUrl(ENVIRONMENTAL.BASEURLS.PREVIEW + constants.DOCUMENTS.URLS.PREVIEWURL + encodeURIComponent(data.fileUrl) + constants.DOCUMENTS.URLS.PREVIEWTITLE + fileTitle + constants.DOCUMENTS.URLS.PREVIEWCOLOR + brandingColour);
                    }
                });
            }
        }
    ]);
})();